//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import SimpleActionButton from "@/components/shared/simple-action-button";

export default {
  name: "SelectStoreDialog",

  components: {
    SimpleActionButton,
  },

  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    selectRegister: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {};
  },

  computed: {
    ...mapState("common/stores", ["stores"]),
    ...mapState("register/registers", ["registers"]),
    items(){
      return this.selectRegister ? this.registers : this.stores
    },
    itemTypeName(){
      return this.selectRegister ? 'register' : 'store'
    }
  },

  methods: {
    close() {
      this.$emit("close", false);
    },
    async onItemSelected(item) {
      if (await this.$root.$confirm(`Confirm ${this.itemTypeName}`, `Proceed with ${this.itemTypeName} ${item.name}?`, { color: '#27333f' })) {
        if(this.selectRegister) {
            this.$emit("onRegisterSelected", item);
        }
        else {
            this.$emit("onStoreSelected", item);
          }
        }
    },
  },
};
